b-form-group<template>
  <validation-observer ref="vehicleRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Description of Vehicle</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row v-if="purchaseQuoteData.inventoryId == null || wherefrom == 'quote-add'">
      <b-col lg="9" md="8" sm="12">
        <b-form-group label="Vehicle" label-for="vehicle">
          <v-select v-model="purchaseQuoteData.inventoryId" :options="dropInventorys" :reduce="(val) => val.id" label="value" input-id="customer-data" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>

      <b-col lg="1" md="2" sm="12" class="text-center" v-if="purchaseQuoteData.inventoryId != null" v-show="$Can('inventory_costs')">
        <b-form-group class="mt-2" label-for="title">
          <b-button v-b-toggle.collapse v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="gradient-light" class="btn-icon">
            <feather-icon icon="InfoIcon" />
          </b-button>
        </b-form-group>
      </b-col>

      <b-col :lg="purchaseQuoteData.inventoryId != null ? 2 : 3" :md="purchaseQuoteData.inventoryId != null ? 3 : 4" sm="12" class="text-center" v-show="$Can('inventory_create')">
        <b-form-group class="mt-2" label-for="title">
          <b-button :to="{ name: 'inventory-add-purchase' }" block variant="relief-dark" class="btn-icon">
            New Inventory
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <span v-if="purchaseQuoteData.inventoryId != null">
      <!-- <b-form-group class="mt-2" label-for="title" v-show="$Can('inventory_edit')">
        <b-button :to="{ name: 'inventory-edit-purchase', params: { id: purchaseQuoteData.inventoryId, purchaseid: purchaseQuoteData.id } }" variant="relief-warning" class="btn-icon"> Edit Inventory </b-button>
      </b-form-group> -->

      <app-collapse accordion>
        <app-collapse-item title="General Information">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Stock Number:</th>
                <td>
                  <span> {{ invInfo.stockNumber ? invInfo.stockNumber : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Model Year:</th>
                <td>
                  <span>
                    {{ invInfo.get_model_year ? invInfo.get_model_year.value : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Make:</th>
                <td>
                  <span>
                    {{ invInfo.get_make ? invInfo.get_make.value : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Model:</th>
                <td>
                  <span>
                    {{ invInfo.get_model ? invInfo.get_model.value : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Model Variation:</th>
                <td>
                  <span>
                    {{ invInfo.modelVariation ? invInfo.modelVariation : ' ' }}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Mileage:</th>
                <td>
                  <span> {{ invInfo.mileageValue ? invInfo.mileageValue : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Mileage Unit:</th>
                <td>
                  <span> {{ invInfo.mileageUnit ? invInfo.mileageUnit : ' ' }} </span>
                </td>
              </tr>

              <tr>
                <th>Body Type:</th>
                <td>
                  <span> {{ invInfo.get_body ? invInfo.get_body.value : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Interior Trim:</th>
                <td>
                  <span> {{ invInfo.interiorTrim ? invInfo.interiorTrim : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Chassis Code:</th>
                <td>
                  <span> {{ invInfo.chassisCode ? invInfo.chassisCode : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Doors:</th>
                <td>
                  <span> {{ invInfo.seatingCapacity ? invInfo.seatingCapacity : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Seats:</th>
                <td>
                  <span> {{ invInfo.numberOfDoors ? invInfo.numberOfDoors : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>External Colour</th>
                <td>
                  <span> {{ invInfo.get_ext_colour ? invInfo.get_ext_colour.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Internal Colour:</th>
                <td>
                  <span> {{ invInfo.get_int_colour ? invInfo.get_int_colour.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Listing Price:</th>
                <td>
                  <span> {{ invInfo.listingPrice ? invInfo.listingPrice : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
        <app-collapse-item title="Engine Technical Data">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Engine Code:</th>
                <td>
                  <span> {{ invInfo.engineCode ? invInfo.engineCode : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Number of Cylinders :</th>
                <td>
                  <span> {{ invInfo.numberOfCylinders ? invInfo.numberOfCylinders : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Engine Alignment :</th>
                <td>
                  <span> {{ invInfo.engineAlignment ? invInfo.engineAlignment : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Engine Position :</th>
                <td>
                  <span> {{ invInfo.enginePosition ? invInfo.enginePosition : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Engine Displacement :</th>
                <td>
                  <span> {{ invInfo.engineDisplacement ? invInfo.engineDisplacement : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Fuel Type :</th>
                <td>
                  <span> {{ invInfo.get_fuel ? invInfo.get_fuel.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Fuel Tank Capacity:</th>
                <td>
                  <span> {{ invInfo.fuelTankCapacity ? invInfo.fuelTankCapacity : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Fuel System:</th>
                <td>
                  <span> {{ invInfo.fuelSystem ? invInfo.fuelSystem : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Bore x Stroke:</th>
                <td>
                  <span> {{ invInfo.boreStroke ? invInfo.boreStroke : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Number of Valves:</th>
                <td>
                  <span> {{ invInfo.numberOfValves ? invInfo.numberOfValves : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Aspiration:</th>
                <td>
                  <span> {{ invInfo.aspiration ? invInfo.aspiration : '' }} </span>
                </td>
              </tr>
              <tr>
                <th>Compression Ratio:</th>
                <td>
                  <span> {{ invInfo.compressionRatio ? invInfo.compressionRatio : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Maximum Horsepower :</th>
                <td>
                  <span> {{ invInfo.maximumHorsepower ? invInfo.maximumHorsepower : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Maximum Torque :</th>
                <td>
                  <span> {{ invInfo.maximumTorque ? invInfo.maximumTorque : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
        <app-collapse-item title="Drivetrain, brakes and suspension">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Drivetrain</th>
                <td>
                  <span> {{ invInfo.get_drive_train ? invInfo.get_drive_train.value : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Transmission :</th>
                <td>
                  <span> {{ invInfo.get_transmmission ? invInfo.get_transmmission.value : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Front Suspension:</th>
                <td>
                  <span> {{ invInfo.frontSuspension ? invInfo.frontSuspension : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Rear Suspension :</th>
                <td>
                  <span> {{ invInfo.rearSuspension ? invInfo.rearSuspension : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Chassis Type :</th>
                <td>
                  <span> {{ invInfo.chassisType ? invInfo.chassisType : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Chassis Materials :</th>
                <td>
                  <span> {{ invInfo.chassisMaterials ? invInfo.chassisMaterials : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Front Brakes - Disc Dimensions:</th>
                <td>
                  <span> {{ invInfo.frontBrakes ? invInfo.frontBrakes : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Rear Brakes - Disc Dimensions:</th>
                <td>
                  <span> {{ invInfo.rearBrakes ? invInfo.rearBrakes : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Front Tyres - Rims Dimensions:</th>
                <td>
                  <span> {{ invInfo.frontTyres ? invInfo.frontTyres : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Rear Tyres - Rims Dimensions s:</th>
                <td>
                  <span> {{ invInfo.rearTyres ? invInfo.rearTyres : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
        <app-collapse-item title="Size and Dimensions">
          <table>
            <caption></caption>
            <tbody>
              <tr>
                <th>Length :</th>
                <td>
                  <span> {{ invInfo.length ? invInfo.length : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Width :</th>
                <td>
                  <span> {{ invInfo.width ? invInfo.width : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Height :</th>
                <td>
                  <span> {{ invInfo.height ? invInfo.height : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Wheelbase :</th>
                <td>
                  <span> {{ invInfo.wheelbase ? invInfo.wheelbase : ' ' }}</span>
                </td>
              </tr>
              <tr>
                <th>Front Axle Width :</th>
                <td>
                  <span> {{ invInfo.frontAxleWidth ? invInfo.frontAxleWidth : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Rear Axle Width :</th>
                <td>
                  <span> {{ invInfo.rearAxleWidth ? invInfo.rearAxleWidth : ' ' }} </span>
                </td>
              </tr>
              <tr>
                <th>Curb Weight :</th>
                <td>
                  <span> {{ invInfo.curbWeight ? invInfo.curbWeight : ' ' }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </app-collapse-item>
      </app-collapse>
    </span>

    <b-collapse id="collapse">
      <b-card>
        <table class="table table-striped table-hover" style="width:100%;">
          <thead class="thead-dark">
            <tr>
              <th colspan="3">Order Id</th>
              <th colspan="3">Type</th>
              <th colspan="3">Amount</th>
              <th colspan="3">Item</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="invInfo.listingPrice != 0">
              <td colspan="3"><strong> Listing Price: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.listingPrice, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>

            <tr v-for="income in invInfo.incomes" :key="income.id">
              <td colspan="3">{{ income.orderId }}</td>
              <td colspan="3">Sale Order</td>
              <td colspan="3">{{ formatPrice(income.price, 2) }}</td>
              <td colspan="3">{{ income.title }}</td>
            </tr>

            <tr v-for="packDown in invInfo.adjustmentDown" :key="packDown.id">
              <td colspan="3">{{ packDown.orderId }}</td>
              <td colspan="3">Adjustment</td>
              <td colspan="3">{{ formatPrice(packDown.price, 2) }}</td>
              <td colspan="3">Pack Down</td>
            </tr>

            <tr v-if="invInfo.vehicleRevenueTotal != 0">
              <td colspan="3"><strong> Revenue Subtotal: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.vehicleRevenueTotal, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>

            <tr v-if="invInfo.purchasePrice > 0">
              <td colspan="3">Quote #{{ invInfo.purchaseQuoteId }}</td>
              <td colspan="3">Purchase Price</td>
              <td colspan="3">{{ formatPrice(invInfo.purchasePrice, 2) }}</td>
              <td colspan="3">Purchase Quote</td>
            </tr>

            <tr v-for="expense in invInfo.expenses" :key="expense.id">
              <td colspan="3">{{ expense.orderId }}</td>
              <td colspan="3">Purchase Order</td>
              <td colspan="3">{{ formatPrice(expense.price, 2) }}</td>
              <td colspan="3">{{ expense.title }}</td>
            </tr>

            <tr v-for="packUp in invInfo.adjustmentUp" :key="packUp.id">
              <td colspan="3">{{ packUp.orderId }}</td>
              <td colspan="3">Adjustment</td>
              <td colspan="3">{{ formatPrice(packUp.price, 2) }}</td>
              <td colspan="3">Pack Up</td>
            </tr>

            <tr v-if="invInfo.pack > 0">
              <td colspan="3"></td>
              <td colspan="3">Pack</td>
              <td colspan="3">{{ formatPrice(invInfo.pack, 2) }}</td>
              <td colspan="3">Pack</td>
            </tr>

            <tr v-if="invInfo.vehicleCostTotal != 0">
              <td colspan="3"><strong> Expense Subtotal: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.vehicleCostTotal, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>

            <tr v-if="invInfo.listingPrice > 0">
              <td colspan="3"><strong> Marginal Profit: </strong></td>
              <td colspan="3"></td>
              <td colspan="3">
                <strong> {{ formatPrice(invInfo.listingPrice + invInfo.vehicleRevenueTotal - invInfo.vehicleCostTotal, 2) }} </strong>
              </td>
              <td colspan="3"></td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton } from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Ripple from 'vue-ripple-directive';

//router.currentRoute.name == 'inventory-edit-purchase'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AppCollapse,
    AppCollapseItem,
    BButton,

    vSelect,
    VueNumericInput,
    ToastificationContent,

    //validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  props: {
    dropInventorys: {
      type: Array,
      required: true,
    },
    purchaseQuoteData: {
      type: Object,
      required: true,
    },

    dropAll: {
      required: true,
    },
    invInfo: {
      type: Object,
      required: false,
    },
  },

  data() {
    var dropCondition = ['NEW', 'USED'];
    var dropMileageUnit = ['KM', 'MI'];
    var wherefrom = router.currentRoute.name;

    return {
      wherefrom,
      dropdownLoading: false,
      dropCondition,
      dropMileageUnit,
      modelOptionsVal: [],
      trimOptionsVal: [],
      oldCardDisabled: false,
    };
  },

  watch: {
    // 'purchaseQuoteData.make': {
    //   handler: function(id, before) {
    //     this.makeSelected(this.purchaseQuoteData.make);
    //   },
    // },
    // 'purchaseQuoteData.model': {
    //   handler: function(id, before) {
    //     if (this.purchaseQuoteData.model != null) {
    //       //  this.msrpClick();
    //       this.modelSelected(this.purchaseQuoteData.model);
    //     }
    //   },
    // },
  },

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    costTitle(data) {
      return data.substring(0, 3) + 'T' + data.substring(3, 5) + 'S' + data.substring(5, 7) + 'O' + data.substring(7, 10) + 'C';
    },

    createNewDropdownValue(val) {
      this.dropdownLoading = true;

      switch (val.who) {
        case 'extColour':
          if (this.dropAll['extColour'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveExtColour', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['extColour'].unshift(response.data);
                this.purchaseQuoteData.extColour = response.data.id;
                this.toastMessage('Ext Colour');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;
        case 'modelYear':
          if (this.dropAll['modelYear'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveModelYear', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['modelYear'].unshift(response.data);
                this.purchaseQuoteData.modelYear = response.data.id;
                this.toastMessage('Model Year');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'intColour':
          if (this.dropAll['intColour'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveIntColour', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['intColour'].unshift(response.data);
                this.purchaseQuoteData.intColour = response.data.id;
                this.toastMessage('Interior Colour');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'make':
          if (this.dropAll['make'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveMakes', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['make'].unshift(response.data);
                this.purchaseQuoteData.make = response.data.id;
                this.toastMessage('Make');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'body':
          if (this.dropAll['body'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveBodyType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['body'].unshift(response.data);
                this.purchaseQuoteData.body = response.data.id;
                this.toastMessage('Body Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'fuel':
          if (this.dropAll['fuel'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveFuelType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['fuel'].unshift(response.data);
                this.purchaseQuoteData.fuel = response.data.id;
                this.toastMessage('Fuel Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'model':
          if (this.modelOptionsVal.find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveModel', { value: val.value.toUpperCase().trim(), makeId: this.purchaseQuoteData.make }).then((response) => {
              if (response.status == 201) {
                this.modelOptionsVal.unshift(response.data);
                this.purchaseQuoteData.model = response.data.id;
                this.toastMessage('Model');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'drivetrain':
          if (this.dropAll['drivetrain'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveDrivetrain', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['drivetrain'].unshift(response.data);
                this.purchaseQuoteData.drivetrain = response.data.id;
                this.toastMessage('Drivetrain');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'trim':
          if (this.trimOptionsVal.find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveTrim', { value: val.value.toUpperCase().trim(), modelId: this.purchaseQuoteData.model }).then((response) => {
              if (response.status == 201) {
                this.trimOptionsVal.unshift(response.data);
                this.purchaseQuoteData.trim = response.data.id;
                this.toastMessage('Trim');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }

          break;

        case 'transmmission':
          if (this.dropAll['transmmission'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveTransmmissionType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['transmmission'].unshift(response.data);
                this.purchaseQuoteData.transmmission = response.data.id;
                this.toastMessage('Transmission');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'vinNo':
          if (this.dropAll['vinNo'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveVinNo', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['vinNo'].unshift(response.data);
                this.purchaseQuoteData.vinNo = response.data.id;
                this.toastMessage('Vehicle Identification Number');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'origenalMarket':
          if (this.dropAll['origenalMarket'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveOrigenalMarket', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['origenalMarket'].unshift(response.data);
                this.purchaseQuoteData.origenalMarket = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpCurrency':
          if (this.dropAll['baseMsrpCurrency'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('lease/saveBaseMsrpCurrency', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['baseMsrpCurrency'].unshift(response.data);
                this.purchaseQuoteData.msrpCurrency = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpValueOptions':
          if (this.msrpValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('purchase/saveMsrpOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.purchaseQuoteData.make,
                modelId: this.purchaseQuoteData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.msrpValueOptions.unshift(response.data);
                  this.msrpValueOption = response.data.id;
                  this.toastMessage('Msrp Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;

        case 'msrpPriceOptions':
          if (val.price >= 0) {
            if (this.msrpPriceOptions.find((x) => x.price == val.price) == undefined) {
              store
                .dispatch('purchase/saveMsrpPrice', {
                  price: val.price.trim(),
                  optionId: this.msrpValueOption,
                })
                .then((response) => {
                  if (response.status == 201) {
                    this.msrpPriceOptions.unshift(response.data);
                    this.msrpPriceOption = response.data.id;
                    this.toastMessage('Msrp Options Price');
                    this.dropdownLoading = false;
                  }
                });
            } else {
              this.dropdownLoading = false;
            }
          } else {
            this.dropdownLoading = false;
            this.msrpPriceOption = null;
          }
          break;

        case 'accessioreValueOptions':
          if (this.accessioreValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('purchase/saveAccessioreOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.purchaseQuoteData.make,
                modelId: this.purchaseQuoteData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.accessioreValueOptions.unshift(response.data);
                  this.accessioreValueOptions.id = response.data.id;
                  this.toastMessage('Accessorie Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;

        default:
          break;
      }
    },

    selectedDropdownValue(val) {
      switch (val.who) {
        case 'extColour':
          this.purchaseQuoteData.extColour = this.dropAll['extColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.extColour != undefined && this.purchaseQuoteData.extColour != 0) {
            this.purchaseQuoteData.extColour = this.dropAll['extColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }

          break;

        case 'modelYear':
          this.purchaseQuoteData.modelYear = this.dropAll['modelYear'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];

          if (this.purchaseQuoteData.modelYear != undefined && this.purchaseQuoteData.modelYear != 0) {
            this.purchaseQuoteData.modelYear = this.dropAll['modelYear'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'intColour':
          this.purchaseQuoteData.intColour = this.dropAll['intColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.intColour != undefined && this.purchaseQuoteData.intColour != 0) {
            this.purchaseQuoteData.intColour = this.dropAll['intColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }

          break;

        case 'make':
          this.purchaseQuoteData.make = this.dropAll['make'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.make != undefined && this.purchaseQuoteData.make != 0) {
            this.purchaseQuoteData.make = this.dropAll['make'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'body':
          this.purchaseQuoteData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.body != undefined && this.purchaseQuoteData.body != 0) {
            this.purchaseQuoteData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'fuel':
          this.purchaseQuoteData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.fuel != undefined && this.purchaseQuoteData.fuel != 0) {
            this.purchaseQuoteData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'model':
          this.purchaseQuoteData.model = this.modelOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.model != undefined && this.purchaseQuoteData.model != 0) {
            this.purchaseQuoteData.model = this.modelOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }

          break;

        case 'drivetrain':
          this.purchaseQuoteData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.drivetrain != undefined && this.purchaseQuoteData.drivetrain != 0) {
            this.purchaseQuoteData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'trim':
          this.purchaseQuoteData.trim = this.trimOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.trim != undefined && this.purchaseQuoteData.trim != 0) {
            this.purchaseQuoteData.trim = this.trimOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'transmmission':
          this.purchaseQuoteData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.transmmission != undefined && this.purchaseQuoteData.transmmission != 0) {
            this.purchaseQuoteData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'vinNo':
          this.purchaseQuoteData.vinNo = this.dropAll['vinNo'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.vinNo != undefined && this.purchaseQuoteData.vinNo != 0) {
            this.purchaseQuoteData.vinNo = this.dropAll['vinNo'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'origenalMarket':
          this.purchaseQuoteData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.origenalMarket != undefined && this.purchaseQuoteData.origenalMarket != 0) {
            this.purchaseQuoteData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'msrpCurrency':
          this.purchaseQuoteData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.purchaseQuoteData.msrpCurrency != undefined && this.purchaseQuoteData.msrpCurrency != 0) {
            this.purchaseQuoteData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;

        case 'msrpValueOptions':
          this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          if (this.msrpValueOption != undefined && this.msrpValueOption != 0) {
            this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0].id;
          }

          this.msrpMakeSelected(this.msrpValueOption);

          break;

        case 'msrpPriceOptions':
          this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0];

          if (this.msrpPriceOption != undefined && this.msrpPriceOption != 0) {
            this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0].id;
          }

          break;

        case 'accessioreValueOptions':
          this.accessioreValueOptions.id = this.accessioreValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          break;

        default:
          break;
      }
    },

    makeSelected(k) {
      if (k != null || k != undefined) {
        this.dropdownLoading = true;
        store
          .dispatch('purchase/getModelByMake', k)
          .then((response) => {
            if (response.data[0] != undefined) {
              this.modelOptionsVal = response.data;
            } else {
              this.modelOptionsVal = [];
            }

            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Model list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    modelSelected(k) {
      /*  if (c == 1) {
        this.purchaseQuoteData.trim = '';
        this.trimOptionsVal = [];
      }*/

      if (k != null || k != undefined) {
        this.dropdownLoading = true;

        store
          .dispatch('purchase/getTrimByModel', k)
          .then((response) => {
            this.formShow = false;
            if (response.data[0] != undefined) {
              this.trimOptionsVal = response.data;
            } else {
              this.trimOptionsVal = [];
            }

            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Trim list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    vinNoCheck(k) {
      if (k != '') {
        this.$emit('update:formShow', true);
        store
          .dispatch('purchase/vinNoCheck', { vinNo: k })
          .then((response) => {
            this.$emit('update:formShow', false);
            if (response.data.res == 0) {
              //start
              //NEXT

              this.purchaseQuoteData.condition = null;
              this.purchaseQuoteData.modelYear = null;
              this.purchaseQuoteData.make = null;
              this.purchaseQuoteData.stockNumber = null;
              this.purchaseQuoteData.model = null;
              this.oldCardDisabled = false;
              //finish
            } else if (response.data.res == 1) {
              this.$swal({
                //   title: 'Information',
                text: 'This vehicle has been previously sold!',
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                //result
              });
            } else {
              this.$swal({
                //   title: 'Information',
                text: 'The VIN number is already registered with an existing vehicle in the inventory. Do you want to proceed to the vehicle details?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Vehicle Details',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  //YES

                  this.purchaseQuoteData.condition = response.data.data.condition;
                  this.purchaseQuoteData.modelYear = response.data.data.modelYear;
                  this.purchaseQuoteData.make = response.data.data.make;
                  this.purchaseQuoteData.stockNumber = response.data.data.stockNumber;
                  this.purchaseQuoteData.model = response.data.data.model;
                  this.oldCardDisabled = true;

                  // this.purchaseQuoteData.inventoryId = response.data.data.id;
                } else {
                  this.purchaseQuoteData.vinNo = '';
                  this.purchaseQuoteData.condition = null;
                  this.purchaseQuoteData.modelYear = null;
                  this.purchaseQuoteData.make = null;
                  this.purchaseQuoteData.stockNumber = null;
                  this.purchaseQuoteData.model = null;
                  this.oldCardDisabled = false;
                }
              });
            }
          })
          .catch((e) => {
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching check control',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    modelClear() {
      this.purchaseQuoteData.model = null;
      this.purchaseQuoteData.trim = null;
    },
    trimClear() {
      this.purchaseQuoteData.trim = null;
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
