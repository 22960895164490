<template>
  <validation-observer ref="coOwnerRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Co-Seller</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-tabs content-class="mt-2" justified>
      <b-tab v-for="(item, i) in purchaseQuoteData.coOwner" :key="item.id" ref="row" :title="'Co-Seller ' + (Number(i) + 1)">
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group label="Customer" label-for="customer">
              <v-select
                v-model="purchaseQuoteData.coOwner[i].customerId"
                @option:selected="coOwnerCustomerSelected(i, purchaseQuoteData.coOwner[i].customerId)"
                @input="coOwnerCustomerDeSelected(i)"
                :options="dropCustomers"
                :reduce="(val) => val.id"
                label="value"
                input-id="customer-data"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="some-radios" class="border-bottom">Seller Type </label>
              <validation-provider name="Seller Type" #default="{ errors }" rules="required">
                <b-form-radio-group class="border-bottom pb-1">
                  <b-form-radio v-model="purchaseQuoteData.coOwner[i].sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="1">
                    Individual
                  </b-form-radio>
                  <b-form-radio v-model="purchaseQuoteData.coOwner[i].sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="2">
                    Business
                  </b-form-radio>
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label v-if="purchaseQuoteData.coOwner[i].sellerType == 2" for="firstName">Business Name</label>
              <label v-else for="firstName">First Name</label>
              <validation-provider :name="purchaseQuoteData.coOwner[i].sellerType == 2 ? 'Business Name' : 'First Name'" #default="{ errors }" rules="required">
                <b-form-input id="firstName" :state="errors.length > 0 ? false : null" v-model="purchaseQuoteData.coOwner[i].firstName" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label v-if="purchaseQuoteData.coOwner[i].sellerType == 2" for="lastName">Operating Name</label>
              <label v-else for="lastName">Last Name </label>
              <b-form-input id="lastName" v-model="purchaseQuoteData.coOwner[i].lastName" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3" v-if="purchaseQuoteData.coOwner[i].sellerType == 2">
            <b-form-group>
              <label for="contactPerson">Contact Person</label>

              <b-form-input id="contactPerson" v-model="purchaseQuoteData.coOwner[i].contactPerson" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3" v-if="purchaseQuoteData.coOwner[i].sellerType == 2">
            <b-form-group>
              <label for="contactInfo">Contact Info</label>
              <b-form-input id="contactInfo" v-model="purchaseQuoteData.coOwner[i].contactInfo" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="driverLicenseNumber">Driver's License Number</label>
              <b-form-input id="driverLicenseNumber" v-model="purchaseQuoteData.coOwner[i].driverLicenseNumber" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="gst">G.S.T Registration</label>
              <b-form-input id="gst" v-model="purchaseQuoteData.coOwner[i].gst" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="phoneNumber">Phone Number</label>
              <validation-provider name="Phone Number" #default="{ errors }" rules="required">
                <b-form-input id="phoneNumber" v-model="purchaseQuoteData.coOwner[i].phoneNumber" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="email">Email</label>
              <validation-provider name="Email" #default="{ errors }" rules="required">
                <b-form-input id="email" v-model="purchaseQuoteData.coOwner[i].email" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="otherIdNumber">Other ID Number</label>
              <b-form-input id="otherIdNumber" v-model="purchaseQuoteData.coOwner[i].otherIdNumber" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="pst">P.S.T Registration</label>
              <b-form-input id="pst" v-model="purchaseQuoteData.coOwner[i].pst" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="unitNumber">Unit Number</label>
              <validation-provider name="Unit Number" #default="{ errors }" rules="required">
                <b-form-input id="unitNumber" v-model="purchaseQuoteData.coOwner[i].unitNumber" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="6">
            <b-form-group>
              <label for="streetAddress">Street Address</label>
              <validation-provider name="Street Address" #default="{ errors }" rules="required">
                <b-form-input id="streetAddress" v-model="purchaseQuoteData.coOwner[i].streetAddress" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="dateOfBirth">Date of Birth</label>
              <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="purchaseQuoteData.coOwner[i].dateOfBirth" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="dealer">Dealer Registration</label>
              <b-form-input id="dealer" v-model="purchaseQuoteData.coOwner[i].dealer" type="text" />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="city">City</label>
              <validation-provider name="City" #default="{ errors }" rules="required">
                <b-form-input id="city" v-model="purchaseQuoteData.coOwner[i].city" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="provence">Province</label>
              <validation-provider name="Province" #default="{ errors }" rules="required">
                <b-form-input id="provence" v-model="purchaseQuoteData.coOwner[i].provence" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="postalCode">Postal Code</label>
              <validation-provider name="Postal Code" #default="{ errors }" rules="required">
                <b-form-input id="postalCode" v-model="purchaseQuoteData.coOwner[i].postalCode" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="6" md="4" xl="3">
            <b-form-group>
              <label for="country">Country</label>
              <validation-provider name="Country" #default="{ errors }" rules="required">
                <b-form-input id="country" v-model="purchaseQuoteData.coOwner[i].country" type="text" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button size="sm" variant="danger" v-if="i != 0" class="float-right" @click="closeTab(i)"> <feather-icon icon="XIcon" class="mr-25" /> Delete </b-button>
      </b-tab>
    </b-tabs>

    <b-button size="sm" @click="addCoOwner" variant="success"> <feather-icon icon="PlusIcon" class="mr-25" /> New Co-Seller </b-button>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BTabs, BTab, BButton } from 'bootstrap-vue';
import store from '@/store';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BTabs,
    BTab,
    BButton,
    ToastificationContent,
    Cleave,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
    dropCustomers: {
      type: Array,
      required: true,
    },
    coOwnerCustomerDatas: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      dropdownLoading: false,
    };
  },
  watch: {
    coOwnerCustomerDatas: function(val) {
      val.forEach((element, index) => {
        this.addCoOwner();
        this.purchaseQuoteData.coOwner[index].sellerType = element.sellerType;
        this.purchaseQuoteData.coOwner[index].phoneNumber = element.phoneNumber;
        this.purchaseQuoteData.coOwner[index].firstName = element.firstName;
        this.purchaseQuoteData.coOwner[index].lastName = element.lastName;
        this.purchaseQuoteData.coOwner[index].email = element.email;
        this.purchaseQuoteData.coOwner[index].unitNumber = element.unitNumber;
        this.purchaseQuoteData.coOwner[index].provence = element.provence;
        this.purchaseQuoteData.coOwner[index].streetAddress = element.streetAddress;
        this.purchaseQuoteData.coOwner[index].city = element.city;
        this.purchaseQuoteData.coOwner[index].postalCode = element.postalCode;
        this.purchaseQuoteData.coOwner[index].country = element.country;
        this.purchaseQuoteData.coOwner[index].gst = element.gst;
        this.purchaseQuoteData.coOwner[index].pst = element.pst;
        this.purchaseQuoteData.coOwner[index].dealer = element.dealer;
        this.purchaseQuoteData.coOwner[index].driverLicenseNumber = element.driverLicenseNumber;
        this.purchaseQuoteData.coOwner[index].otherIdNumber = element.otherIdNumber;
        this.purchaseQuoteData.coOwner[index].dateOfBirth = element.dateOfBirth;
        this.purchaseQuoteData.coOwner[index].customerId = element.id;
        this.purchaseQuoteData.coOwner[index].contactPerson = element.contactPerson;
        this.purchaseQuoteData.coOwner[index].contactInfo = element.contactInfo;
      });
    },
  },
  computed: {},
  created() {},
  methods: {
    addCoOwner() {
      this.purchaseQuoteData.coOwner.push({
        // id: (this.nextTodoId += this.nextTodoId),
        sellerType: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        unitNumber: null,
        provence: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        gst: null,
        pst: null,
        dealer: null,
        driverLicenseNumber: null,
        otherIdNumber: null,
        dateOfBirth: null,
        contactPerson: null,
        contactInfo: null,
      });
    },
    coOwnerCustomerSelected(index, id) {
      if (id != null) {
        store
          .dispatch('lease/getCustomerDropDownId', id)
          .then((response) => {
            this.purchaseQuoteData.coOwner[index].sellerType = response.data.sellerType;
            this.purchaseQuoteData.coOwner[index].phoneNumber = response.data.phoneNumber;
            this.purchaseQuoteData.coOwner[index].firstName = response.data.firstName;
            this.purchaseQuoteData.coOwner[index].lastName = response.data.lastName;
            this.purchaseQuoteData.coOwner[index].email = response.data.email;
            this.purchaseQuoteData.coOwner[index].unitNumber = response.data.unitNumber;
            this.purchaseQuoteData.coOwner[index].provence = response.data.provence;
            this.purchaseQuoteData.coOwner[index].streetAddress = response.data.streetAddress;
            this.purchaseQuoteData.coOwner[index].city = response.data.city;
            this.purchaseQuoteData.coOwner[index].postalCode = response.data.postalCode;
            this.purchaseQuoteData.coOwner[index].country = response.data.country;
            this.purchaseQuoteData.coOwner[index].gst = response.data.gst;
            this.purchaseQuoteData.coOwner[index].pst = response.data.pst;
            this.purchaseQuoteData.coOwner[index].dealer = response.data.dealer;
            this.purchaseQuoteData.coOwner[index].driverLicenseNumber = response.data.driverLicenseNumber;
            this.purchaseQuoteData.coOwner[index].otherIdNumber = response.data.otherIdNumber;
            this.purchaseQuoteData.coOwner[index].dateOfBirth = response.data.dateOfBirth;
            this.purchaseQuoteData.coOwner[index].contactPerson = response.data.contactPerson;
            this.purchaseQuoteData.coOwner[index].contactInfo = response.data.contactInfo;
          })
          .catch((error) => {});
      }
    },
    coOwnerCustomerDeSelected(index) {
      this.purchaseQuoteData.coOwner[index].sellerType = null;
      this.purchaseQuoteData.coOwner[index].phoneNumber = null;
      this.purchaseQuoteData.coOwner[index].firstName = null;
      this.purchaseQuoteData.coOwner[index].lastName = null;
      this.purchaseQuoteData.coOwner[index].email = null;
      this.purchaseQuoteData.coOwner[index].unitNumber = null;
      this.purchaseQuoteData.coOwner[index].provence = null;
      this.purchaseQuoteData.coOwner[index].streetAddress = null;
      this.purchaseQuoteData.coOwner[index].city = null;
      this.purchaseQuoteData.coOwner[index].postalCode = null;
      this.purchaseQuoteData.coOwner[index].country = null;
      this.purchaseQuoteData.coOwner[index].gst = null;
      this.purchaseQuoteData.coOwner[index].pst = null;
      this.purchaseQuoteData.coOwner[index].dealer = null;
      this.purchaseQuoteData.coOwner[index].driverLicenseNumber = null;
      this.purchaseQuoteData.coOwner[index].otherIdNumber = null;
      this.purchaseQuoteData.coOwner[index].dateOfBirth = null;
      this.purchaseQuoteData.coOwner[index].contactPerson = null;
      this.purchaseQuoteData.coOwner[index].contactInfo = null;
    },
    closeTab(index) {
      this.purchaseQuoteData.coOwner.splice(index, 1);
    },
  },
};
</script>
